const svg = '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>';

class CopyDataApp extends HTMLElement {
  constructor() {
    super();

    const label = this.getAttribute('label');
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.setAttribute('aria-label', label);
    this.button.title = label;

    this.button.innerHTML = svg;
  }

  connectedCallback() {
    this.appendChild(this.button);
    this.button.addEventListener('click', () => {
      const data = this.getAttribute('data');

      navigator.clipboard.writeText(data)
        .then(() => {
          console.log('Copied');
          const toast = document.getElementById('toast');
          const event = new CustomEvent('message', { detail: '🥳 Dane skopiowane!' });
          toast.dispatchEvent(event);
        })
        .catch((error) => {
          console.log('Could not copy to clipboard', error);
        });
    });
  }
}

const init = () => {
  // eslint-disable-next-line no-undef
  if (
    !(
      window.customElements
      && navigator.clipboard.writeText
    )
  ) {
    return;
  }

  if (!window.customElements.get('copy-data-app')) {
    window.customElements.define('copy-data-app', CopyDataApp);
  }
};

init();
