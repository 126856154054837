class RazemToast extends HTMLElement {
  constructor() {
    super();

    this.toastContent = document.createElement('div');
    this.toastContent.classList.add('razem-toast__content', 'paper');
    this.toastWrapper = document.createElement('div');
    this.toastWrapper.classList.add('razem-toast');
    this.toastWrapper.setAttribute('role', 'status');
    this.toastWrapper.setAttribute('aria-live', 'polite');

    this.toastWrapper.appendChild(this.toastContent);

    this.isOpen = false;
    this.closing = false;
    this.autoCloseTimeout = 0;
    this.nextMessage = null;
  }

  connectedCallback() {
    this.appendChild(this.toastWrapper);
    this.addEventListener('message', (event) => {
      const { detail } = event;
      this.receiveMessage(detail);
    });
  }

  receiveMessage(message) {
    this.nextMessage = message;
    this.open();
  }

  async open() {
    if (this.closing) {
      return;
    }

    if (this.isOpen) {
      await this.close();
    }

    this.toastContent.innerHTML = `<p>${this.nextMessage}</p>`;
    this.toastWrapper.classList.add('razem-toast--open');
    this.isOpen = true;

    this.autoCloseTimeout = setTimeout(() => this.close(), 3000);
  }

  close() {
    clearTimeout(this.autoCloseTimeout);
    this.closing = true;
    return new Promise((resolve) => {
      this.toastWrapper.classList.remove('razem-toast--open');
      setTimeout(() => {
        // this.toastContent.innerHTML = '';
        this.isOpen = false;
        this.closing = false;
        resolve();
      }, 250);
    });
  }
}

const init = () => {
  // eslint-disable-next-line no-undef
  if (
    !(
      window.customElements
      && navigator.clipboard.writeText
    )
  ) {
    return;
  }

  if (!window.customElements.get('razem-toast')) {
    window.customElements.define('razem-toast', RazemToast);
  }
};

init();
